import React from 'react';
import css from 'src/theme/css';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import TextBlock from 'src/components/TextBlock';
import Form from 'src/components/Form';
import ImageGroup from 'src/components/ImageGroup';
import ModuleWrapper from 'src/components/ModuleWrapper';
import { Grid, Flex, Box } from 'src/components/FlexBox';
import AdobeBanner from 'src/components/AdobeBanner';
import zipWith from 'lodash/zipWith';
import Text from 'src/components/Text';
import ColumnModule from 'src/components/ColumnModule';

const Stats = ({ titles, subtitles, ...other }) => (
  <Flex
    {...other}
    flexDirection={['column', null, 'row']}
    gx="1px"
    gy="1px"
    css={css({ borderRadius: 2, overflow: 'hidden' })}
  >
    {zipWith(titles, subtitles, (title, subtitle) => (
      <Flex
        flexDirection={['row', null, 'column']}
        gy={2}
        gx={4}
        css={css({ flexGrow: 1, padding: 4, bg: 'modeAlpha.1' })}
      >
        <Text variant="heading.l">{title}</Text>
        <hr
          css={css({
            display: ['none', null, 'block'],
            border: 'none',
            height: '1px',
            width: '100%',
            maxWidth: 6,
            bg: 'purpleLight',
          })}
        />
        <Text>{subtitle}</Text>
      </Flex>
    ))}
  </Flex>
);

const LookupComponent = ({ __typename, ...other }) => {
  const lookup = {
    ContentfulTextBlock: <TextBlock size="m" {...other} />,
    ContentfulImageGroup: <ImageGroup css={{ borderRadius: 12 }} {...other} />,
    ContentfulForm: <Form {...other} />,
  };
  return get(lookup, __typename, <Box {...other} />);
};

const alignContentLookup = {
  Left: 'flex-start',
  Center: 'center',
  Right: 'flex-end',
  Document: 'center',
  Wide: 'center',
};

const textAlignLookup = {
  Left: 'start',
  Center: 'center',
  Right: 'start',
  Document: 'start',
  Wide: 'center',
};

const alignItemsLookup = {
  Left: 'stretch',
  Center: 'center',
  Right: 'stretch',
  Document: 'stretch',
  Wide: 'center',
};

const maxWidthLookup = {
  Left: '60rem',
  Center: '60rem',
  Right: '60rem',
  Document: '70rem',
  Wide: '90rem',
};

export default ({
  top,
  left,
  right,
  bottom,
  verticalOrder,
  shouldCollapseMargin,
  ...other
}) => {
  const showAdobeBanner = false; // other.slug && other.slug === 'home#hero';
  const heroSpacing = ['35px', '30px', '100px'];

  return (
    <ModuleWrapper
      {...other}
      flexDirection="row"
      alignSelf="stretch"
      justifyContent="center"
    >
      {showAdobeBanner && <AdobeBanner />}
      <Grid
        g={5}
        gridTemplateColumns={['auto', null, '1fr 1fr']}
        gridTemplateRows={[
          left && right ? 'auto auto' : '1fr 1fr',
          null,
          'auto',
        ]}
        alignItems="center"
        alignSelf="stretch"
        css={css({
          width: '100%',
          px: 'pageMargin',
          pt: shouldCollapseMargin ? 0 : 'sectionMargin',
          pb: 'sectionMargin',
          mt: showAdobeBanner ? heroSpacing : '0',
        })}
      >
        {top && (
          <LookupComponent
            {...top}
            css={css({
              maxWidth: '70rem',
            })}
            gridColumn={['1 / span 1', null, '1 / span 2']}
          />
        )}
        <LookupComponent
          {...left}
          id={verticalOrder === 'inverted' ? '1' : '0'}
          css={css({ order: [verticalOrder === 'inverted' ? 1 : 0, null, 0] })}
        />
        <LookupComponent {...right} />
        {bottom && (
          <Stats {...bottom} gridColumn={['1 / span 1', null, '1 / span 2']} />
        )}
      </Grid>
    </ModuleWrapper>
  );
};

export const query = graphql`
  fragment NewTwoUpModuleFragment on ContentfulNewTwoUpModule {
    id
    slug
    colorMode
    isViewportHeight
    hasScrim
    verticalOrder
    top {
      ... on ContentfulTextBlock {
        __typename
        ...TextBlockFragment
      }
    }
    left {
      ... on ContentfulTextBlock {
        ...TextBlockFragment
      }
      ... on ContentfulImageGroup {
        ...ImageGroupFragment
      }
      ... on ContentfulForm {
        ...FormFragment
      }
    }
    right {
      ... on ContentfulTextBlock {
        ...TextBlockFragment
      }
      ... on ContentfulImageGroup {
        ...ImageGroupFragment
      }
      ... on ContentfulForm {
        ...FormFragment
      }
    }
    bottom {
      ... on ContentfulStats {
        titles
        subtitles
      }
    }
    shouldCollapseMargin
    background {
      ...ImageGroupFragment
    }
  }
`;
