import React from 'react';
import PropTypes from 'prop-types';

const Adobe = ({ className }) => (
  <svg className={className} viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.12207 0.837891H0V22.608L9.12207 0.837891Z" fill="white"/>
    <path d="M15.5404 0.837891H24.6506V22.608L15.5404 0.837891Z" fill="white"/>
    <path d="M12.3312 8.86133L18.1372 22.6078H14.3279L12.5927 18.2312H8.34363L12.3312 8.86133Z" fill="white"/>
  </svg>
);

Adobe.propTypes = {
  className: PropTypes.string,
};

export default Adobe;
